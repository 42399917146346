import React, { useState } from "react";
import classNames from "classnames";

export const ResponsiveImage = props => {
  const {
    alt,
    className,
    src,
    lazy,
    onload,
    dimensions = [],
    quality = 55
  } = props;

  const defaultDimension = dimensions[0];

  const defaultSrc = `${src}?q=${quality}&w=${defaultDimension.width}&h=${defaultDimension.height}`;
  const placeholderSrc = `${src}?q=55&w=2&h=2`;

  const [loaded, setLoaded] = useState(false);
  const [placeholderLoaded, setPlaceholderLoaded] = useState(false);

  const Sources = () =>
    dimensions.map((dimension, index) => {
      const { width, height, mediaQuery, quality = 55 } = dimension;
      const dimensionUrl = `${src}?q=${quality}&w=${width}&h=${height}`;
      return (
        <source
          key={`${src}-${index}`}
          media={mediaQuery}
          data-srcset={lazy ? dimensionUrl : undefined}
          srcSet={lazy ? undefined : dimensionUrl}
        />
      );
    });

  const onimageLoad = () => {
    setLoaded(true);
    onload && onload();
  };

  const onPlaceholderLoad = () => {
    setPlaceholderLoaded(true);
  };

  const placeholderClass = classNames("placeholder-image", {
    loaded
  });

  return (
    <div className={className}>
      {placeholderLoaded && (
        <picture>
          <Sources />
          <img
            data-src={defaultSrc}
            src={lazy ? undefined : defaultSrc}
            onLoad={onimageLoad}
            alt={alt}
          />
        </picture>
      )}
      <div className={placeholderClass}>
        <img src={placeholderSrc} alt={alt} onLoad={onPlaceholderLoad} />
      </div>
    </div>
  );
};
