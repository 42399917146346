import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";

import { homeSelector } from "./home.selector";
import { getCollections } from "../store/collections.redux";
import {
  getLatestVillas,
  getRecent,
  getDestinations,
  getPopularVillas
} from "../store/villas.redux";

import CollectionItem from "../modules/collection-item/collection-item-component";
import MemberSignup from "../modules/member-signup/member-signup";
import PromoItem from "../modules/promo-item/promo-item-component";
import VillaItem from "../modules/villas/villa-item/villa-item-component";
import SearchInput from "../modules/search/search-input";
import Destinations from "../modules/destination-item/destinations";
import Testimonials from "../modules/testimonials/testimonials-component";

import generic from "../constants/generic";

class Home extends Component {
  state = {
    refreshing: false,
    recent: null,
    latest: null,
    destinations: null,
    collections: null
  };

  componentDidMount() {
    this.props.getDestinations();
    this.props.getCollections();
    this.props.getLatestVillas();
    this.props.getPopularVillas();
    this.props.getRecent();
  }

  recentlyViewed() {
    const { recent } = this.props;
    if (!recent || !recent.length) return null;
    const villas = recent
      .slice(0, 4)
      .map(villa => <VillaItem key={villa.id} {...villa} />);

    return (
      <div className="strip">
        <div className="main">
          <div className="heading">
            <h1>Recently viewed</h1>
            <p>Pick up right where you left off...</p>
          </div>
          <div className="list">{villas}</div>
        </div>
      </div>
    );
  }

  favourites() {
    const { shortlist } = this.props;
    if (!shortlist || !shortlist.length) return null;

    const villas = shortlist
      .slice(0, 4)
      .map(villa => <VillaItem key={villa.id} {...villa} />);

    return (
      <div className="strip">
        <div className="main">
          <div className="heading">
            <h1>Your Shortlist</h1>
            <p>
              These are some of the villas you have shortlisted.{" "}
              <NavLink to="/shortlist">View all</NavLink>
            </p>
          </div>
          <div className="list">{villas}</div>
        </div>
      </div>
    );
  }

  latestAdditions() {
    const { latest } = this.props;
    if (!latest || !latest.length) return null;

    const villas = latest
      .slice(0, 4)
      .map(villa => <VillaItem key={villa.id} {...villa} />);

    return (
      <div className="strip">
        <div className="main">
          <div className="heading">
            <h1>Latest additions</h1>
            <p>
              Discover some of the luxury villas we have recently added to our
              collection.
            </p>
          </div>
          <div className="list">{villas}</div>
        </div>
      </div>
    );
  }

  popular() {
    const { popular } = this.props;
    if (!popular || !popular.length) return null;
    const villas = popular
      .slice(0, 4)
      .map(villa => <VillaItem key={villa.id} {...villa} />);

    return (
      <div className="strip">
        <div className="main">
          <div className="heading">
            <h1>Spotlight</h1>
            <p>
              Here are some of the villas that people are looking at right now.
              Get in touch with us to discuss availability.
            </p>
          </div>
          <div className="list">{villas}</div>
        </div>
      </div>
    );
  }

  collections = () => {
    const { collections } = this.props;
    if (!collections || !collections.length) return null;

    const collectionsComponent = collections.map(collection => (
      <CollectionItem key={collection.slug} {...collection} />
    ));

    return (
      <div className="strip">
        <div className="main">
          <div className="heading">
            <h1>Browse our collections</h1>
            <p>
              Looking for a little bit of inspiration? Why not check out one of
              our collections.
            </p>
          </div>
          <div className="list collections">{collectionsComponent}</div>
        </div>
      </div>
    );
  };

  renderVilla = data => {
    if (!data) return null;
    return <VillaItem {...data} />;
  };

  renderCollection = data => <CollectionItem {...data} />;

  render() {
    const { destinations } = this.props;

    return (
      <Fragment>
        <div className="strip">
          <div className="promo banner">
            <div className="banner-image">
              <img
                src="https://static.chilledvillas.com/headers/architects.jpg?w=1280&h=720"
                alt="Architects House - Chilled Villas"
              />
            </div>
            <div className="banner-link">
              <h1>
                chilled<span className="brand">villas</span>
              </h1>
              <h2>Inspirations for curious &amp; style-savvy travellers</h2>
              <SearchInput />
            </div>
          </div>
        </div>
        {this.favourites()}
        {this.recentlyViewed()}
        <Destinations items={destinations} />
        {this.latestAdditions()}
        <PromoItem>
          <h2>Personalised recommendations</h2>
          <p>
            If you do not have time to browse, or are still undecided, tell us
            what you are looking for and we will send you some fabulous holiday
            villa suggestions to suit your style and budget.
          </p>
          <div className="buttons">
            <a
              className="button blue"
              href="https://chilledvillas.typeform.com/to/X6W21W"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} /> Get in touch
            </a>
            <a className="button" href={`tel:${generic.phoneNumbers.general}`}>
              <FontAwesomeIcon icon={faPhone} /> Call{" "}
              {generic.phoneNumbers.general}
            </a>
            <button onClick={() => window.Intercom("show")}>
              <FontAwesomeIcon icon={faComments} /> Chat with us
            </button>
          </div>
        </PromoItem>
        {this.collections()}
        <Testimonials />
        {this.popular()}
        <MemberSignup />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCollections,
      getDestinations,
      getLatestVillas,
      getPopularVillas,
      getRecent
    },
    dispatch
  );

export default withRouter(connect(homeSelector, mapDispatchToProps)(Home));
