import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

const Testimonial = props => {
  return (
    <div className="testimonial">
      <div className="quote">
        <FontAwesomeIcon icon={faQuoteLeft} />
      </div>
      <div className="copy">
        <p>{props.copy}</p>
        <p className="author">{props.name}</p>
      </div>
    </div>
  );
};

export default Testimonial;
