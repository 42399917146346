import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class PromoItem extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    loaded: false
  };

  render() {
    const { children } = this.props;
    return (
      <div className="promo text-center">
        <div className="text">{children}</div>
      </div>
    );
  }
}

export default withRouter(PromoItem);
