import { createSelector } from "reselect";

const villasState = (state, props) => state.villas;
const isShortlisted = (state, props) =>
  !!state.villas.shortlist.find(villa => villa.id === props.id);

export const villaItemSelector = createSelector(
  [isShortlisted, villasState],
  (isShortlisted, villasState) => {
    return {
      isShortlisted,
      shortlist: villasState.shortlist
    };
  }
);
