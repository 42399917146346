import React from "react";
import DestinationItem from "../../modules/destination-item/destination-item-component";

const Destinations = props => {
  const { items: destinations } = props;
  if (!destinations || !destinations.length) return null;

  const destinationsComponent = destinations.map(destination => (
    <DestinationItem key={destination.slug} {...destination} />
  ));

  return (
    <div className="main">
      <div className="heading">
        <h1>Our destinations</h1>
      </div>
      <div className="list destinations">{destinationsComponent}</div>
    </div>
  );
};

export default Destinations;
