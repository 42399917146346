import React from "react";
import { NavLink } from "react-router-dom";

const DestinationItem = props => {
  const { name, slug, total } = props;
  return (
    <NavLink to={`/villas/${slug}/`}>
      <div className="destination">
        <div className="destination-image">
          <img
            src={`https://static.chilledvillas.com/countries/${slug}.jpg?w=640&h=480`}
            alt={name}
          />
        </div>
        <div className="title">
          <div className="name">{name}</div>
          <div className="count">{total} villas</div>
        </div>
      </div>
    </NavLink>
  );
};

export default DestinationItem;
