import { createSelector } from "reselect";

const searchState = state => state.search;
const villaState = state => state.villas;

export const searchSelector = createSelector(
  [searchState, villaState],
  (searchState, villaState) => {
    const {
      guests: { adults, children }
    } = searchState;
    const guests = adults + children;

    return {
      ...searchState,
      destinations: villaState.destinations,
      guestTitle: guests
        ? `${guests} ${guests > 1 ? "guests" : "guest"}`
        : "Filter by Guests"
    };
  }
);
