import React from "react";
import Testimonial from "./testimonial";

const testimonials = [
  {
    name: "GM, London",
    copy:
      "Thank you for your speedy responses and help with booking our holiday in Greece. We had a wonderful time and will be in touch regarding next years holiday."
  },
  {
    name: "Ekatrina,  UK",
    copy:
      "If you’re looking for Portuguese paradise, this is it. It would be difficult to improve on Casa do Pego."
  },
  {
    name: "AT, Edinburgh",
    copy:
      "Excellent choice of villas to choose from and helpful sales team. Great service."
  }
];

const Testimonials = () => {
  return (
    <div className="testimonials-component text-center">
      <h1>What some of our guests have been saying...</h1>
      <div className="list">
        {testimonials.map((testimonial, key) => (
          <Testimonial
            key={key}
            copy={testimonial.copy}
            name={testimonial.name}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
