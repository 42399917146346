import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-1">
              <h1 className="brand">
                chilled<span>villas</span>
              </h1>
              <ul>
                <li>
                  <NavLink to="/about/">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/villas/">Our Villas</NavLink>
                </li>
                <li>
                  <NavLink to="/collections/">Collections</NavLink>
                </li>

                <li>
                  <NavLink to="/travel-journal/">Travel Journal</NavLink>
                </li>
                <li>
                  <NavLink to="/offers/">Special Offers</NavLink>
                </li>
              </ul>
            </div>

            <div className="col-sm-1">
              <h1>Our Destinations</h1>
              <ul>
                <li>
                  <NavLink to="/villas/greece/">Villas in Greece</NavLink>
                </li>
                <li>
                  <NavLink to="/villas/spain/">Villas in Spain</NavLink>
                </li>
                <li>
                  <NavLink to="/villas/portugal/">Villas in Portugal</NavLink>
                </li>
              </ul>
            </div>

            <div className="col-sm-1">
              <h1>Useful Links</h1>
              <ul>
                <li>
                  <NavLink to="/about/contact/">Contact Us</NavLink>
                </li>
                {/* <li><NavLink to="/app/">Download our app</NavLink></li> */}
                <li>
                  <NavLink to="/about/join-us/">Apply to be listed</NavLink>
                </li>
                <li>
                  <a
                    href="https://medium.com/perfectly-chilled-engineering/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Tech Blog
                  </a>
                </li>
                <li>
                  <NavLink to="/about/terms/">Terms &amp; Conditions</NavLink>
                </li>
                <li>
                  <NavLink to="/about/privacy/">Privacy Policy</NavLink>
                </li>
              </ul>
            </div>

            <div className="col-sm-1 social">
              <h1>{"We're Social"}</h1>
              <a
                href="https://instagram.com/chilledvillas/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon className="icon" icon={faInstagram} />
              </a>
              <a
                href="https://facebook.com/chilledvillas/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon className="icon" icon={faFacebookSquare} />
              </a>
              <a
                href="https://twitter.com/chilledvillas/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon className="icon" icon={faTwitterSquare} />
              </a>
              {/* <h1>Download our app</h1>
              <NavLink className="app" to="/app/">Android <FontAwesomeIcon icon={faAndroid} /></NavLink>
              <NavLink className="app" to="/app/">iOS <FontAwesomeIcon icon={faApple} /></NavLink> */}
            </div>
          </div>
          <div className="copyright">
            <img
              src="https://static.chilledvillas.com/chilled_stamp.jpg?q=55&w=360&h=360"
              alt="Chilled Villas logo"
            />{" "}
            &copy; Perfectly Chilled Travel Directory LLP.
          </div>
        </div>
      </footer>
    );
  }
}
