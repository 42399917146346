import { createSelector } from "reselect";

const collectionsState = state => state.collections;
const villasState = state => state.villas;

export const homeSelector = createSelector(
  [collectionsState, villasState],
  (collectionsState, villasState) => {
    return {
      collections: collectionsState.items,
      destinations: villasState.destinations,
      latest: villasState.latestItems,
      popular: villasState.popular,
      recent: villasState.recent,
      shortlist: villasState.shortlist
    };
  }
);
