import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const defaultMetadata = {
  title: "Chilled Villas",
  description:
    "Chilled Villas is an an online portfolio offering eclectic mix of luxury villas & hip hideaways in the Greek Islands, Portugal & Spain. From old classics to new finds, our aim is to share these inspirational finds and discoveries within one dedicated online environment.",
  keywords: [
    "villas",
    "holiday homes",
    "chilled villas",
    "villa for rent",
    "villa with pool",
    "Luxury villa rentals",
    "honeymoon"
  ],
  image: "https://static.chilledvillas.com/headers/architects.jpg?w=1280&h=630"
};

const SEO = ({ description, lang, meta, image, keywords, title }) => (
  <Helmet
    htmlAttributes={{
      lang
    }}
    title={title}
    titleTemplate={`%s | ${defaultMetadata.title}`}
    meta={[
      {
        name: `description`,
        content: description
      },
      {
        property: `og:title`,
        content: title
      },
      {
        property: `og:description`,
        content: description
      },
      {
        property: `og:type`,
        content: `website`
      },
      {
        property: `og:image`,
        content: image
      },
      {
        name: `twitter:card`,
        content: `summary`
      },
      {
        name: `twitter:creator`,
        content: defaultMetadata.author
      },
      {
        name: `twitter:title`,
        content: title
      },
      {
        name: `twitter:description`,
        content: description
      }
    ]
      .concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `)
            }
          : []
      )
      .concat(meta)}
  />
);

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: defaultMetadata.keywords,
  description: defaultMetadata.description,
  image: defaultMetadata.image
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
