import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Sticky from "react-stickynode";

import {
  getShortlist,
  hideShortlist,
  showShortlist,
  toggleMenu
} from "../../store/villas.redux";

import { navigationSelector } from "./navigation-selector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as heartIcon } from "@fortawesome/free-solid-svg-icons/faHeart";
import ShortListDropdown from "../shortlist/shortlist-dropdown";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

class Navigation extends Component {
  static propTypes = {
    getShortlist: PropTypes.func.isRequired,
    shortlistCount: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    showMenu: PropTypes.bool.isRequired,
    showShortlist: PropTypes.func.isRequired
  };

  componentDidMount() {
    window.Intercom("boot", {
      app_id: "abxlu258"
    });
    this.props.getShortlist();
  }

  render = () => {
    const { show, shortlistCount } = this.props;
    return (
      <Sticky innerZ={16}>
        <header className="navigation">
          <div className="logo">
            <NavLink to="/">
              <img
                src="https://static.chilledvillas.com/chilled_stamp.jpg?q=55&w=360&h=360"
                alt="Chilled Villas logo"
              />
              <div className="wordmark">
                chilled<span className="brand">villas</span>
              </div>
            </NavLink>
          </div>
          <div className={`links ${this.props.showMenu ? "show" : ""}`}>
            <div className="close" onClick={this.props.toggleMenu}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <ul>
              <li>
                <NavLink onClick={this.props.toggleMenu} to="/" exact>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.props.toggleMenu} to="/about/">
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.props.toggleMenu} to="/villas/">
                  Our Villas
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.props.toggleMenu} to="/collections/">
                  Collections
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.props.toggleMenu} to="/offers/">
                  Special Offers
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.props.toggleMenu} to="/travel-journal/">
                  Travel Journal
                </NavLink>
              </li>
              <li className="heart">
                <a className="button" href="/shortlist">
                  <FontAwesomeIcon icon={heartIcon} />
                  <div className="number">{shortlistCount}</div>
                </a>
                <ShortListDropdown show={show} />
              </li>
              {/* <li className="login"><NavLink to="/account/">Login</NavLink></li> */}
            </ul>
          </div>
          <div className="mobile-navigation" onClick={this.props.toggleMenu}>
            <span />
            <span />
            <span />
          </div>
        </header>
      </Sticky>
    );
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { hideShortlist, showShortlist, getShortlist, toggleMenu },
    dispatch
  );
export default withRouter(
  connect(navigationSelector, mapDispatchToProps)(Navigation)
);
