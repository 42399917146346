import React, { Component } from "react";

export default class MemberSignup extends Component {
  render() {
    return (
      <div className="promo member-signup">
        <div className="text">
          <form
            action="https://perfectlychilledvillas.us12.list-manage.com/subscribe/post?u=286f1720c7cdb31c0e67740a6&amp;id=9d7d9294a9"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <h1>Subscribe to our Newsletter</h1>
            <div className="form">
              <input
                id="mce-EMAIL"
                name="EMAIL"
                type="text"
                autoComplete="false"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                autoCorrect="false"
                placeholder="Enter your email address"
              />
              <button>Subscribe</button>
              <p>
                By subscribing to our newsletter, you can save up to £100 off
                your first booking with us.
              </p>
              <p>
                Our newsletter gives you access to the latest villas and special
                offers, as well as occasional tips and travel inspirations.
              </p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
