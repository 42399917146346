import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
export default class VillaItem extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  };

  state = {
    loaded: false
  };

  render() {
    const { description, name, slug } = this.props;
    return (
      <div className="item collection">
        <Link to={`/collections/${slug}/`}>
          <div className="image">
            <LazyLoad>
              <img
                src={`https://do3c6p7he6puo.cloudfront.net/images/collections/${slug}.png`}
                alt=""
              />
            </LazyLoad>
          </div>
          <div className="content">
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
        </Link>
      </div>
    );
  }
}
