import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHome,
  faMapMarkerAlt,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { API_ENDPOINT } from "../../config";

import { searchSelector } from "./search.selector";

import {
  closeActiveFilter,
  decrementGuests,
  openFilter,
  incrementGuests,
  resetGuests
} from "../../store/search.redux";

class SearchInput extends Component {
  state = {
    query: "",
    focused: false,
    highlight: 0,
    results: null,
    recent: null
  };

  handleClickOutside = () => {
    // this.props.activeFilter === 'collections' &&
    // this.props.closeActiveFilter()
  };

  handleKey = event => {
    if (event.keyCode === 38 || event.keyCode === 40) event.preventDefault();

    let number = -1;
    let updateQuery = false;

    const { results, highlight } = this.state;
    if (!results) return;

    const length = results.length;
    switch (event.keyCode) {
      case 38: // up
        number = (highlight + length - 1) % length;
        updateQuery = true;
        break;
      case 40: // down
        number = (highlight + 1) % length;
        updateQuery = true;
        break;
      case 13: // enter
        // navigate away
        return this.search();
      default:
        break;
    }

    if (!updateQuery) return;
    this.setState({
      highlight: number,
      query: updateQuery ? results[number].text : this.state.query
    });
  };

  handleHover = index => {
    this.setState({
      highlight: index
    });
  };

  handleChange = event => {
    const query = event.target.value;
    this.setState({
      query,
      highlight: 0
    });
    if (query.length < 1) {
      this.setState({
        results: null
      });
      return;
    }
    axios.get(`${API_ENDPOINT}/search/${query}/`).then(({ data }) => {
      this.setState({
        results: data.slice(0, 6)
      });
    });
  };

  handleFocus = () => {
    this.setState({
      focused: true
    });
  };

  renderFilters = () => {
    return (
      <Fragment>
        <button className="button">
          <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> Filter by guests
        </button>
      </Fragment>
    );
  };

  renderQuery = () => {
    if (this.state.query.length < 1 || !this.state.results) return;

    const { results } = this.state;

    if (!results.length) {
      return (
        <Fragment>
          {/* <h1>No results</h1> */}
          <p>Sorry. We could not find what you are looking for.</p>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {results.slice(0, 6).map(({ text, type, url }, index) => {
          return (
            <Link
              key={index}
              onMouseDown={this.cancelBlur}
              onMouseEnter={() => this.handleHover(index)}
              to={url}
              className={`suggestion ${
                this.state.highlight === index ? "highlighted" : ""
              }`}
            >
              <div className="icon">
                <FontAwesomeIcon
                  icon={type === "villa" ? faHome : faMapMarkerAlt}
                />
              </div>
              <p dangerouslySetInnerHTML={this.highlight(`${text}`)}></p>
            </Link>
          );
        })}
      </Fragment>
    );
  };

  highlight = item => {
    const querystr = this.state.query;
    const reg = new RegExp(querystr, "gi");
    return {
      __html: item.replace(reg, str => {
        return `<strong>${str}</strong>`;
      })
    };
  };

  renderSuggestions = () => {
    if (!this.state.focused) return null;

    return (
      <div className="suggestions">
        {/* { this.renderFilters() } */}
        {this.state.query.length >= 1 && this.renderQuery()}
        <h1>Need some inspiration?</h1>
        <div className="buttons">
          <a className="button" href="/villas/">
            Our destinations
          </a>
          <a className="button" href="/collections/">
            Our collections
          </a>
        </div>
      </div>
    );
  };

  search = () => {
    // this.props.closeActiveFilter()
    // do something
    const { highlight, results } = this.state;
    if (highlight >= 0) {
      this.props.history.push(results[highlight].url);
    } else {
      // this.props.history.push(`/s/${query}`)
    }
  };

  render() {
    return (
      <div className="search">
        <div className="search-input">
          <input
            type="text"
            autoComplete="false"
            onFocus={this.handleFocus}
            onChange={this.handleChange}
            onKeyDown={this.handleKey}
            autoCorrect="false"
            value={this.state.query}
            placeholder="Search for a villa or destination..."
          />
          <FontAwesomeIcon icon={faSearch} />
        </div>
        {this.renderSuggestions()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeActiveFilter,
      decrementGuests,
      incrementGuests,
      openFilter,
      resetGuests
    },
    dispatch
  );
export default withRouter(
  connect(searchSelector, mapDispatchToProps)(SearchInput)
);
