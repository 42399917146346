import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as heartIcon } from "@fortawesome/free-regular-svg-icons/faHeart";
import { faHeart as heartIconSolid } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faBed } from "@fortawesome/free-solid-svg-icons/faBed";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  addToShortlist,
  removeFromShortlist
} from "../../../store/villas.redux";
import { villaItemSelector } from "./villa-item.selector";
import { ResponsiveImage } from "../../../components/image";
import LazyLoad from "react-lazyload";

const SpecialOffer = props => {
  if (!props.offers || !props.offers.length) return null;

  return (
    <div className="offer-label">
      {props.offers.length} {props.offers.length > 1 ? "offers" : "offer"}
    </div>
  );
};

const dimensions = [
  {
    mediaQuery: "(min-width: 768px)",
    width: 960,
    height: 540
  },
  {
    mediaQuery: "(min-width: 480px)",
    width: 540,
    height: 320
  },
  {
    width: 480,
    height: 240
  }
];

const thumbDimensions = [
  {
    mediaQuery: "(min-width: 768px)",
    width: 240,
    height: 130
  },
  {
    width: 120,
    height: 65
  }
];

class VillaItem extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sleeps: PropTypes.number.isRequired,
    bedrooms: PropTypes.number.isRequired,
    rate: PropTypes.shape({
      minRate: PropTypes.number.isRequired,
      maxRate: PropTypes.number.isRequired,
      weekly: PropTypes.bool.isRequired
    }),
    region: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    imagePaths: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    addToShortlist: PropTypes.func.isRequired
  };

  static defaultProps = {
    name: null,
    sleeps: null,
    bedrooms: null,
    rate: {
      price: null,
      weekly: null
    },
    offers: [],
    region: null,
    country: null,
    image: null
  };

  state = {
    loaded: true
  };

  handleLoad = () => {
    this.setState({
      loaded: true
    });
  };

  render() {
    const {
      id,
      name,
      sleeps,
      bedrooms,
      rate: { minRate, weekly },
      region,
      country,
      imagePaths,
      url,
      offers
    } = this.props;
    const shortlisted = this.props.isShortlisted;
    return (
      <LazyLoad height={480} offset={200}>
        <div className="villa-item">
          <div className="images">
            {imagePaths.slice(0, 1).map((image, key) => {
              const src = `https://static.chilledvillas.com/${image}`;
              return (
                <div key={key} className="image">
                  <Link to={url} target="_blank">
                    <ResponsiveImage
                      className="villa-image-container"
                      src={src}
                      alt=""
                      dimensions={dimensions}
                    />
                  </Link>
                  <div
                    className="favourite"
                    onClick={() =>
                      shortlisted
                        ? this.props.removeFromShortlist(id)
                        : this.props.addToShortlist(id)
                    }
                  >
                    <FontAwesomeIcon
                      icon={shortlisted ? heartIconSolid : heartIcon}
                    />
                  </div>
                  <SpecialOffer offers={offers} />
                </div>
              );
            })}
            {imagePaths.slice(1, 4).map((image, key) => {
              const src = `https://static.chilledvillas.com/${image}`;
              return (
                <div key={key} className="image">
                  <Link to={url} target="_blank">
                    <ResponsiveImage
                      className="villa-image-container"
                      src={src}
                      alt=""
                      dimensions={thumbDimensions}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
          <Link to={url} target="_blank">
            <div className="content">
              <div className="info">
                <p className="location">
                  {region}, {country}
                </p>
                <h1>{name}</h1>
                <p className="rates">
                  From £{minRate.toLocaleString()}{" "}
                  {weekly ? "per week" : "per day"}
                </p>
                <div className="sleeps">
                  <FontAwesomeIcon icon={faUsers} /> {sleeps}{" "}
                  <FontAwesomeIcon icon={faBed} /> {bedrooms}
                </div>
              </div>
            </div>
          </Link>
          {/*  */}
        </div>
      </LazyLoad>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addToShortlist, removeFromShortlist }, dispatch);
export default withRouter(
  connect(villaItemSelector, mapDispatchToProps)(VillaItem)
);
